@font-face {
    font-family: 'RocGrotesk';
    src: local('RocGrotesk'),url('../../../public/assets/fonts/Kostic - Roc Grotesk Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'RocGroteskBold';
    src: local('RocGroteskBold'),url('../../../public/assets/fonts/Kostic - Roc Grotesk Bold.otf') format('opentype');
}
@font-face {
    font-family: 'RocGroteskExtraBold';
    src: local('RocGroteskBold'),url('../../../public/assets/fonts/Kostic - Roc Grotesk ExtraBold.otf') format('opentype');
}